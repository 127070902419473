import './SettingsPasswordChangeContent.less'

import { Button, Form, Input } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  LoginType,
  MfaMethod,
  SettingsChangePasswordOptions,
  fetchTenantData,
  settingsChangePassword,
  settingsCheckMfaMethod
} from '../../../requests'
import { validateRequired } from '../../../validation'
import { UserContext } from '../../auth'
import { PasswordRejectionError, validatePassword } from '../../auth/validatePassword'
import { DatacFormItem, DatacMessage, DatacTitle } from '../../common'
import { SettingsLayout } from '../SettingsLayout'
import { SettingsDisableMfaModal } from './SettingsDisableMfaModal'
import { SettingsEnableMfaModal } from './SettingsEnableMfaModal'

enum MfaModalType {
  Enable = 'enable',
  Disable = 'disable'
}

export const SettingsPasswordChangeContent: React.FC = () => {
  const intlPasswordChange = useScopedIntl('settings.password_change')
  const intlMfa = useScopedIntl('auth.mfa')
  const intl = useScopedIntl('')
  const [form] = Form.useForm()
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [newPasswordRejectionError, setNewPasswordRejectionError] = useState<PasswordRejectionError>(null)
  const [currentPasswordRejectionError, setCurrentPasswordRejectionError] = useState(false)
  const [openedMfaModal, setOpenedMfaModal] = useState<MfaModalType>(null)
  const [mfaMethod, setMfaMethod] = useState<MfaMethod>()
  const { user } = useContext(UserContext)
  const [minPasswordLength, setMinPasswordLength] = useState<number>(null)

  useEffect(() => {
    settingsCheckMfaMethod({
      onSuccess: setMfaMethod,
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
    fetchTenantData({
      onSuccess: ({ minPasswordLength }) => setMinPasswordLength(minPasswordLength),
      onRequestError: code => DatacMessage.genericError(intl, code)
    })
  }, [])

  const getNewPasswordRejectionErrorLabel = () => {
    switch (newPasswordRejectionError) {
      case PasswordRejectionError.TheSameAsCurrent:
        return intl('auth.signup.field.password.validation.same_as_current')
      case PasswordRejectionError.TooCommon:
        return intl('auth.signup.field.password.validation.too_common')
      case PasswordRejectionError.TooSimilar:
        return intl('auth.signup.field.password.validation.too_similar')
      default:
        return undefined
    }
  }
  const onCancel = () => {
    form.resetFields()
    setIsEditingOn(false)
    resetValidation()
  }

  const onSave = () => {
    form.submit()
  }

  const onEdit = () => {
    setIsEditingOn(true)
  }

  const onSubmit = (data: SettingsChangePasswordOptions) => {
    setIsSaving(true)
    settingsChangePassword(data, {
      onSuccess: () => {
        setIsEditingOn(false)
        setIsSaving(false)
        form.resetFields()
        DatacMessage.success(intlPasswordChange('save.success.title'), intlPasswordChange('save.success.description'))
      },
      onRequestError: () => DatacMessage.error(intlPasswordChange('error.title'), intlPasswordChange('error.subtitle')),
      onInvalidPassword: () => setCurrentPasswordRejectionError(true),
      onPasswordTheSameAsCurrent: () => setNewPasswordRejectionError(PasswordRejectionError.TheSameAsCurrent),
      onPasswordTooCommon: () => setNewPasswordRejectionError(PasswordRejectionError.TooCommon),
      onPasswordTooSimilar: () => setNewPasswordRejectionError(PasswordRejectionError.TooSimilar),
      onError: () => setIsSaving(false)
    })
  }

  const resetValidation = () => {
    if (currentPasswordRejectionError) setCurrentPasswordRejectionError(false)
    if (newPasswordRejectionError) setNewPasswordRejectionError(null)
  }

  const mfaButton = (type: MfaModalType) => (
    <Button size="large" type="primary" onClick={() => setOpenedMfaModal(type)}>
      {intlMfa(`${type}.title`)}
    </Button>
  )

  return (
    user.loginType === LoginType.Password && (
      <SettingsLayout>
        <div className="password-change-content">
          <DatacTitle type="h2" className="password-change-content__header">
            {intlPasswordChange('title')}
          </DatacTitle>
          <div className="password-change-content__body">
            <div className="password-change-content__form">
              <Form
                form={form}
                onFinish={data => onSubmit(data as SettingsChangePasswordOptions)}
                onChange={resetValidation}
              >
                <div className="password-change-content__row">
                  <div className="password-change-content__label">{intlPasswordChange('current_password.label')}</div>
                  <div className="password-change-content__control">
                    <DatacFormItem
                      name="currentPassword"
                      label=" "
                      validate={validateRequired(intlPasswordChange('current_password.validation.required'))}
                      error={
                        currentPasswordRejectionError
                          ? intlPasswordChange('current_password.validation.wrong')
                          : undefined
                      }
                    >
                      <Input.Password
                        disabled={!isEditingOn}
                        size="large"
                        type="password"
                        placeholder={intlPasswordChange('current_password.placeholder')}
                      />
                    </DatacFormItem>
                  </div>
                </div>

                <div className="password-change-content__row">
                  <div className="password-change-content__label">{intlPasswordChange('new_password.label')}</div>
                  <div className="password-change-content__control">
                    <DatacFormItem
                      name="newPassword"
                      label=" "
                      validate={value =>
                        validatePassword(value, minPasswordLength).catch(messageId =>
                          Promise.reject(intl(messageId, { length: minPasswordLength }))
                        )
                      }
                      error={newPasswordRejectionError ? getNewPasswordRejectionErrorLabel() : undefined}
                    >
                      <Input.Password
                        disabled={!isEditingOn}
                        size="large"
                        type="password"
                        placeholder={intlPasswordChange('new_password.placeholder')}
                      />
                    </DatacFormItem>
                  </div>
                </div>

                <div className="password-change-content__row">
                  <div className="password-change-content__label">
                    {intlPasswordChange('password_confirmation.label')}
                  </div>
                  <div className="password-change-content__control">
                    <Form.Item noStyle dependencies={['newPassword']}>
                      {({ getFieldValue }) => {
                        return (
                          <DatacFormItem
                            name="passwordConfirmation"
                            validateFirst
                            label=" "
                            validate={[
                              value => {
                                if (!value || getFieldValue('newPassword') === value) {
                                  return Promise.resolve()
                                }
                                return Promise.reject(intlPasswordChange('password_confirmation.validation.same'))
                              },
                              validateRequired(intlPasswordChange('password_confirmation.validation.required'))
                            ]}
                          >
                            <Input.Password
                              disabled={!isEditingOn}
                              size="large"
                              type="password"
                              placeholder={intlPasswordChange('password_confirmation.placeholder')}
                            />
                          </DatacFormItem>
                        )
                      }}
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
            <div className="password-change-content__form-controls">
              {isEditingOn || isSaving ? (
                <>
                  <Button size="large" type="default" onClick={onCancel}>
                    {intlPasswordChange('cancel')}
                  </Button>
                  <Button loading={isSaving} size="large" type="primary" onClick={onSave}>
                    {intlPasswordChange('save')}
                  </Button>
                </>
              ) : (
                <Button size="large" type="primary" onClick={onEdit}>
                  {intlPasswordChange('edit')}
                </Button>
              )}
              {mfaButton(mfaMethod === MfaMethod.APP ? MfaModalType.Disable : MfaModalType.Enable)}
            </div>
          </div>
        </div>
        <SettingsEnableMfaModal
          isOpened={openedMfaModal === MfaModalType.Enable}
          onClose={() => setOpenedMfaModal(null)}
          setMfaMethod={setMfaMethod}
        />
        <SettingsDisableMfaModal
          isOpened={openedMfaModal === MfaModalType.Disable}
          onClose={() => setOpenedMfaModal(null)}
          setMfaMethod={setMfaMethod}
        />
      </SettingsLayout>
    )
  )
}
